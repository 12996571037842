<template>
    <b-card
        v-if="data"
        class="card-congratulation-medal"
    >
        <h5><b>Bienvenido {{ data.name }}!</b></h5>
        <b-card-text class="font-small-3">
            Tiene pendiente {{ data.num_citas }} citas.
        </b-card-text>

        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            :to="{ name: 'Visitas' }"
        >
            Ver citas
        </b-button>
        <b-img
            :src="require('@/assets/images/illustration/badge.svg')"
            class="congratulation-medal"
            alt="Medal Pic"
        />
    </b-card>
</template>

<script>
import {
    BCard, BCardText, BLink, BButton, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'

export default {
    components: {
        BCard,
        BCardText,
        BLink,
        BImg,
        BButton,
    },
    directives: {
        Ripple,
    },
    props: {
        data: { type: Object, default: null },
    },
    methods: {
        kFormatter,
    },
}
</script>
