<template>
    <section id="dashboard-Dashboard">
        <b-row class="match-height">
            <b-col
                xl="4"
                md="6"
            >
                <Dashboard-medal :data="data"/>
            </b-col>
            <b-col
                xl="8"
                md="6"
            >
                <Dashboard-statistics :data="data.statisticsItems"/>
            </b-col>
        </b-row>

        <!--<b-row class="match-height">
            <b-col lg="4">
                <b-row class="match-height">
                    &lt;!&ndash; Bar Chart - Orders &ndash;&gt;
                    <b-col
                        lg="6"
                        md="3"
                        cols="6"
                    >
                        <Dashboard-order-chart :data="data.statisticsOrder"/>
                    </b-col>
                    &lt;!&ndash;/ Bar Chart - Orders &ndash;&gt;
                    <b-col
                        lg="6"
                        md="3"
                        cols="6"
                    >
                        <Dashboard-profit-chart :data="data.statisticsProfit"/>
                    </b-col>
                    <b-col
                        lg="12"
                        md="6"
                    >
                        <Dashboard-earnings-chart :data="data.earningsChart"/>
                    </b-col>
                </b-row>
            </b-col>

            &lt;!&ndash; Revenue Report Card &ndash;&gt;
            <b-col lg="8">
                <Dashboard-revenue-report :data="data.revenue"/>
            </b-col>
            &lt;!&ndash;/ Revenue Report Card &ndash;&gt;
        </b-row>-->

        <b-row class="match-height">
            <!-- Company Table Card -->
            <b-col lg="12">
                <clientes />
                <!--<Dashboard-company-table :table-data="data.companyTable"/>-->
            </b-col>
            <!--/ Company Table Card -->

            <!-- Developer Meetup Card -->
            <b-col
                lg="4"
                md="6"
            >
                <!--<Dashboard-meetup :data="data.meetup"/>-->
            </b-col>
            <!--/ Developer Meetup Card -->

            <!-- Browser States Card -->
            <b-col
                lg="4"
                md="6"
            >
                <!--<Dashboard-browser-states/>-->
            </b-col>
            <!--/ Browser States Card -->

            <!-- Goal Overview Card -->
            <b-col
                lg="4"
                md="6"
            >
               <!-- <Dashboard-goal-overview :data="data.goalOverview"/>-->
            </b-col>
            <!--/ Goal Overview Card -->

            <!-- Transaction Card -->
            <b-col
                lg="4"
                md="6"
            >
                <Dashboard-transactions :data="data.transactionData"/>
            </b-col>
            <!--/ Transaction Card -->
        </b-row>
    </section>
</template>

<script>
    import { BRow, BCol } from 'bootstrap-vue'

    // import { getUserData } from '@/auth/utils'
    import useJwt from '@/auth/jwt/useJwt'
    import DashboardMedal from './DashboardMedal.vue'
    import DashboardStatistics from './DashboardStatistics.vue'
    import DashboardRevenueReport from './DashboardRevenueReport.vue'
    import DashboardOrderChart from './DashboardOrderChart.vue'
    import DashboardProfitChart from './DashboardProfitChart.vue'
    import DashboardEarningsChart from './DashboardEarningsChart.vue'
    import DashboardCompanyTable from './DashboardCompanyTable.vue'
    import DashboardMeetup from './DashboardMeetup.vue'
    import DashboardBrowserStates from './DashboardBrowserStates.vue'
    import DashboardGoalOverview from './DashboardGoalOverview.vue'
    import DashboardTransactions from './DashboardTransactions.vue'
    import Clientes from '../Customers/Clientes'

    export default {
        components: {
            BRow,
            BCol,
            useJwt,
            DashboardMedal,
            DashboardStatistics,
            DashboardRevenueReport,
            DashboardOrderChart,
            DashboardProfitChart,
            DashboardEarningsChart,
            DashboardCompanyTable,
            DashboardMeetup,
            DashboardBrowserStates,
            DashboardGoalOverview,
            DashboardTransactions,
            Clientes
        },
        data () {
            return {
                data: {},
            }
        },
        mounted () {
            this.getUserLogged()
        },
        methods: {
            async getUserLogged () {
                const logged = localStorage.getItem('userData')
                const user = JSON.parse(logged)
                this.data = user
                await this.getNumCitas(user.id)
            },
            getNumCitas(idUser) {
                useJwt.getCountCitas({id: idUser})
                    .then(response => {
                        this.data['num_citas'] = response.data
                    })
            },
        },
    }
</script>

<style lang="scss">
    // @import '../src/@core/scss/vue/pages/dashboard-Ecommerce.scss';
    // @import '../src/@core/scss/vue/libs/chart-apex.scss';
</style>
